<template>
  <section class="modules-wrap">
    <!-- table start -->
    <el-table
      :data="tableData"
      height="100%"
      :border="true"
      :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    >
      <el-table-column type="index" align="center" width="100" label="序号">
      </el-table-column>
      <el-table-column
        prop="category_name"
        align="center"
        label="品类"
        min-width="150"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.category_name || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="time"
        align="center"
        label="时间段"
        min-width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="number"
        align="center"
        label="销售件数"
      ></el-table-column>
      <el-table-column prop="as_number" align="center" label="售后件数">
      </el-table-column>
      <el-table-column
        prop="aftersale_proportion"
        align="center"
        label="售后占比"
      ></el-table-column>
    </el-table>
    <!-- table end -->
  </section>
</template>

<script>
export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.modules-wrap {
  flex: 1;
  overflow: hidden;
  .table {
    height: calc(100% - 20px);
  }
}
</style>
